const LANGUAGES = [
  {
    title: "English",
    key: "en"
  },
  {
    title: "हिन्दी",
    key: "hi"
  },
  {
    title: "తెలుగు",
    key: "te"
  }
];

export default LANGUAGES;
