import { message } from "antd";
import {
  Button,
  EnneaLogoElement,
  ErrorElement,
  ErrorMessage,
  InputElement,
  LargeButton,
  LoginFormContainer,
  SignInImageElement,
  TitleElement,
  WelcomeTextElement
} from "common/components/StyledElements";
import APIConstants from "common/constants/APIConstants";
import { passwordRegex, phoneNumberRegex } from "common/constants/regex";
import { useAuthContext } from "common/contexts/AuthContext";
import SignInImage from "common/icons/SignInImage.jpg";
import EnneaLogo from "common/icons/ennea_logo.png";
import { validateNumberInput } from "common/utils";
import { api } from "common/utils/APIMethods";
import { EventType, ReactGATracker } from "common/utils/analyticsTracker";
import { MESSAGE_TYPE, showMessage } from "common/utils/message";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;
const SubTitleElement = styled.div`
  font-size: 26px;
  font-weight: 300;
  line-height: 28.6px;
  color: #11243d;
  align-self: center;
`;
const TextElement = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 28.6px;
  color: #40567c;
  align-self: center;
`;

const UpdatePassword = ({ sessionId, handleError }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resetPassword } = APIConstants;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      passwordConfirm: ""
    }
  });

  const registerOptions = {
    password: {
      required: t("password_required"),
      pattern: {
        value: passwordRegex,
        message: t("password_error_message")
      }
    },
    passwordConfirm: {
      required: t("confirm_password_required"),
      validate: (value) =>
        value === getValues().password || t("password_not_match")
    }
  };

  const submitPassword = () =>
    api({
      url: resetPassword,
      method: "PUT",
      body: { sessionId, password: getValues().password }
    });

  const { isLoading, mutate } = useMutation(() => submitPassword(), {
    onSuccess: (response) => {
      showMessage(
        "password_update_successfully",
        MESSAGE_TYPE.successTranslate
      );
      navigate("/login", {
        state: { username: response.username },
        replace: true
      });
    },
    onError: (error) => {
      handleError(error.message);
    }
  });

  const handleSubmitPassword = (formData) => {
    ReactGATracker(EventType.CONFIRM_PASSWORD_ON_SUBMIT_CLICK);
    mutate(formData);
  };

  return (
    <>
      <TitleElement>
        <Trans i18nKey="enter_new_password" />
      </TitleElement>
      <InputElement
        name="password"
        type="password"
        {...register("password", registerOptions.password)}
        error={errors?.password}
        placeholder={t("enter_new_password")}
        onFocus={() => {
          ReactGATracker(EventType.CONFIRM_PASSWORD_NEW_PASSWORD_FIELD_CLICK);
        }}
      />
      {errors.password && (
        <ErrorElement>{errors.password.message}</ErrorElement>
      )}
      <TitleElement>
        <Trans i18nKey="re_enter_password" />
      </TitleElement>
      <InputElement
        name="passwordConfirm"
        type="password"
        {...register("passwordConfirm", registerOptions.passwordConfirm)}
        error={errors?.passwordConfirm}
        placeholder={t("re_enter_password")}
        onFocus={() => {
          ReactGATracker(EventType.CONFIRM_PASSWORD_FIELD_CLICK);
        }}
      />
      {errors.passwordConfirm && (
        <ErrorElement>{errors.passwordConfirm.message}</ErrorElement>
      )}
      <br />
      <LargeButton
        primary="true"
        disabled={!isValid}
        loading={isLoading}
        onClick={handleSubmit(handleSubmitPassword)}
      >
        <Trans i18nKey="done" />
      </LargeButton>
    </>
  );
};

const VerifyOTP = ({
  sessionId,
  phoneNumber,
  isPhoneNumberLogin,
  sendingOTP,
  handleResendOTP,
  handleSuccess
}) => {
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  const { otpValidate, phoneNumberLogin } = APIConstants;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      otp: ""
    }
  });

  const registerOptions = {
    required: true,
    minLength: { value: 6 },
    maxLength: { value: 6 }
  };

  const getUserAuthDataFromPhoneNumberMutation = useMutation(
    (params) =>
      api({
        url: phoneNumberLogin,
        method: "POST",
        params
      }),
    {
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/dashboard", { replace: true });
      },
      onError: (error) => {
        showMessage(
          error?.message ?? "check_username_password",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const { isLoading, mutate } = useMutation(
    (formData) =>
      api({
        url: `${otpValidate}/${parseInt(formData.otp, 10)}/${sessionId}`
      }),
    {
      onSuccess: (response) => {
        showMessage("otp_verify_successfull", MESSAGE_TYPE.successTranslate);
        if (isPhoneNumberLogin) {
          const params = {
            phoneNumber,
            otpSession: sessionId
          };
          getUserAuthDataFromPhoneNumberMutation.mutate(params);
        } else {
          handleSuccess({ sessionId: response.sessionId, mode: 2 });
        }
      },
      onError: (error) => {
        showMessage(
          error?.message ?? "otp_verify_unsuccessfull",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const handleVerifyOTP = (formData) => {
    ReactGATracker(EventType.OTP_VERIFY_BUTTON_CLICK);
    mutate(formData);
  };

  return (
    <>
      <SubTitleElement>
        <Trans i18nKey="enter_digit_otp" />
      </SubTitleElement>
      <br />
      <InputElement type="number" {...register("otp", registerOptions)} />
      {errors.otp && (
        <ErrorMessage>
          <Trans i18nKey="enter_valid_otp" />
        </ErrorMessage>
      )}
      <br />
      <TextElement>
        <Trans i18nKey="otp_not_received" />
        <Button
          style={{ borderWidth: 0 }}
          loading={sendingOTP}
          onClick={handleResendOTP}
        >
          <Trans i18nKey="resend" />
        </Button>
      </TextElement>
      <br />
      <LargeButton
        primary="true"
        loading={isLoading || getUserAuthDataFromPhoneNumberMutation.isLoading}
        onClick={handleSubmit(handleVerifyOTP)}
      >
        <Trans i18nKey={isPhoneNumberLogin ? "login_to_valuemedi" : "verify"} />
      </LargeButton>
    </>
  );
};

const GenerateOTP = ({ phNumber, sendingOTP, handleGenerateOTP }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      phoneNumber: phNumber || ""
    }
  });
  const { t } = useTranslation();
  const registerOptions = {
    required: t("enter_phone"),
    pattern: {
      value: phoneNumberRegex,
      message: t("enter_valid_phone")
    }
  };

  return (
    <>
      <TitleElement>
        <Trans i18nKey="enter_mobile" />
      </TitleElement>
      <InputElement
        type="number"
        onKeyDown={validateNumberInput}
        {...register("phoneNumber", registerOptions)}
        error={errors?.phoneNumber}
        placeholder={t("enter_mobile")}
        onFocus={() => {
          ReactGATracker(EventType.FORGOT_PASSWORD_PHONE_NUMBER_FIELD_CLICK);
        }}
      />
      {errors.phoneNumber && (
        <ErrorElement>{errors.phoneNumber.message}</ErrorElement>
      )}
      <LargeButton
        primary="true"
        disabled={!isValid}
        loading={sendingOTP}
        onClick={handleSubmit(handleGenerateOTP)}
      >
        <Trans i18nKey="verify" />
      </LargeButton>
    </>
  );
};

const ResetPassword = () => {
  const locationProps = useLocation();
  const { otpGenerate } = APIConstants;
  const [mode, setMode] = useState(0);
  const sessionId = useRef("");
  const isPhoneNumberLoginRef = useRef(false);
  const phoneNumber = useRef(locationProps?.state?.phNumber || "");

  useEffect(() => {
    const data = locationProps?.state;
    if (data?.isPhoneNumberLogin) {
      isPhoneNumberLoginRef.current = data.isPhoneNumberLogin;
      phoneNumber.current = data.phoneNumber;
      sessionId.current = data.sessionId;
      setMode(data.mode);
    }
  }, [locationProps?.state]);

  const handleSuccess = (response) => {
    sessionId.current = response.sessionId;
    setMode(response.mode);
  };

  const handleError = (errorMessage) => {
    message.error(errorMessage);
  };

  const { isLoading: sendingOTP, mutate } = useMutation(
    (formData) =>
      api({
        url: `${otpGenerate}/${parseInt(formData.phoneNumber, 10)}`
      }),
    {
      onSuccess: (response) => {
        showMessage("otp_send_successful", MESSAGE_TYPE.successTranslate);
        handleSuccess({ sessionId: response.sessionId, mode: 1 });
      },
      onError: (err) => {
        handleError(err.message);
      }
    }
  );

  const handleGenerateOTP = (formData) => {
    phoneNumber.current = formData.phoneNumber;
    ReactGATracker(EventType.FORGOT_PASSWORD_SEND_OTP_BUTTON_CLICK);
    mutate(formData);
  };

  const handleResendOTP = () => {
    ReactGATracker(EventType.OTP_RESEND_BUTTON_CLICK);
    mutate({ phoneNumber: phoneNumber.current });
  };

  const renderFormComponent = () => {
    if (mode === 1) {
      return (
        <VerifyOTP
          sessionId={sessionId.current}
          phoneNumber={phoneNumber.current}
          isPhoneNumberLogin={isPhoneNumberLoginRef.current}
          sendingOTP={sendingOTP}
          handleResendOTP={handleResendOTP}
          handleSuccess={handleSuccess}
        />
      );
    }
    if (mode === 2) {
      return (
        <UpdatePassword
          sessionId={sessionId.current}
          handleError={handleError}
        />
      );
    }
    return (
      <GenerateOTP
        phNumber={phoneNumber.current}
        sendingOTP={sendingOTP}
        handleGenerateOTP={handleGenerateOTP}
      />
    );
  };

  return (
    <Container>
      <SignInImageElement alt="" src={SignInImage} />
      <LoginFormContainer>
        <WelcomeTextElement>
          <Trans i18nKey="welcome_to" />
        </WelcomeTextElement>
        <br />
        <EnneaLogoElement alt="Ennea" src={EnneaLogo} />
        <br />
        <br />
        {renderFormComponent()}
      </LoginFormContainer>
    </Container>
  );
};

export default ResetPassword;
