const skipWords = [
  "CAPSULS",
  "MG",
  "MV",
  "SYRUPL",
  "VIAL",
  "spray",
  "sprays",
  "SERUM",
  "SERUMS",
  "STEEL",
  "STEELS",
  "GLASS",
  "GLOVES",
  "CV",
  "BANDAGE",
  "BANDAGES",
  "COLDACT",
  "INJ",
  "INJECTION",
  "INJECTIONS",
  "tabs",
  "tab",
  "tablets",
  "tablet",
  "cap",
  "caps",
  "capsule",
  "capsules",
  "gel",
  "cream",
  "drop",
  "drops",
  "mg",
  "mcg",
  "ml",
  "gm",
  "GM",
  "%",
  "IU",
  "iu",
  "CM",
  "cm",
  "ML",
  "Amp",
  "Syrup"
];

export default skipWords;
