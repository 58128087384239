import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

export const UserContext = createContext();

const userSearchPreferencesInitialState = {
  suppliers: [],
  offers: "show_all_products",
  others: {
    clear_search: false,
    retain_search: false,
    hide_supplier: false,
    hide_manufacturer: false
  }
};

const poSearchPreferencesInitialState = {
  offers: "show_all_products",
  others: {
    hide_manufacturer: false,
    modify_qty: false,
    show_suggested: false
  }
};

export default function UserContextProvider({ children }) {
  const [userSearchPreferences, setUserSearchPreferences] = useState(
    userSearchPreferencesInitialState
  );
  const [poSearchPreferences, setPoSearchPreferences] = useState(
    poSearchPreferencesInitialState
  );

  const placeOrderSearchRef = useRef(null);

  const getSavedSuppliers = () => {
    const searchPreferences =
      JSON.parse(localStorage.getItem("user_search_preferences")) || "";

    let updatedSearchPreferences = {};

    if (searchPreferences) {
      updatedSearchPreferences = {
        ...updatedSearchPreferences,
        ...searchPreferences
      };
    }

    if (Object.keys(updatedSearchPreferences).length > 0) {
      setUserSearchPreferences(updatedSearchPreferences);
    }
  };

  const getSavedPoPreferences = () => {
    const savedPoSearchPreferences =
      JSON.parse(localStorage.getItem("user_po_search_preferences")) || "";

    let updatedPoSearchPreferences = {};

    if (savedPoSearchPreferences) {
      updatedPoSearchPreferences = {
        ...updatedPoSearchPreferences,
        ...savedPoSearchPreferences
      };
    }

    if (Object.keys(updatedPoSearchPreferences).length > 0) {
      setPoSearchPreferences(updatedPoSearchPreferences);
    }
  };

  useEffect(() => {
    getSavedSuppliers();
    getSavedPoPreferences();
  }, []);

  const persistUserSearchPreferences = (updatedSearchPreferences) => {
    setUserSearchPreferences(updatedSearchPreferences);

    localStorage.setItem(
      "user_search_preferences",
      JSON.stringify(updatedSearchPreferences)
    );
  };

  const persistPoSearchPreferences = (updatedSearchPreferences) => {
    setPoSearchPreferences(updatedSearchPreferences);

    localStorage.setItem(
      "user_po_search_preferences",
      JSON.stringify(updatedSearchPreferences)
    );
  };

  const userSearchPreferencesValues = useMemo(
    () => ({
      isFilterDeal: userSearchPreferences.offers === "products_with_offers",
      isSearchClear: userSearchPreferences?.others?.clear_search || false,
      retainSearchEnabled:
        userSearchPreferences?.others?.retain_search || false,
      isHideManufacturer:
        userSearchPreferences?.others?.hide_manufacturer || false,
      isHideSupplier: userSearchPreferences?.others?.hide_supplier || false
    }),
    [userSearchPreferences]
  );

  const poSearchPreferencesValues = useMemo(
    () => ({
      isFilterDealPO: poSearchPreferences.offers === "products_with_offers",
      isHideManufacturerPO:
        poSearchPreferences?.others?.hide_manufacturer || false,
      isModifyQty: poSearchPreferences?.others?.modify_qty || false,
      showSuggested: poSearchPreferences?.others?.show_suggested || false
    }),
    [poSearchPreferences]
  );

  const userContextValues = useMemo(
    () => ({
      userSearchPreferences,
      userSearchPreferencesValues,
      persistUserSearchPreferences,
      poSearchPreferences,
      placeOrderSearchRef,
      poSearchPreferencesValues,
      persistPoSearchPreferences
    }),
    [
      userSearchPreferencesValues,
      userSearchPreferences,
      poSearchPreferences,
      poSearchPreferencesValues
    ]
  );

  return (
    <UserContext.Provider value={userContextValues}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => useContext(UserContext);
