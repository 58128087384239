const urls = {
  GAMMA: "https://gamma.valuemedi.com/",
  BETA: "https://beta.valuemedi.com/",
  PROD: "https://www.valuemedi.com/",
  LOCAL: "http://localhost:8081/"
};

export const GTMTags = {
  BETA: "G-XS3DN1T9HS",
  PROD: "G-TDQPF5HHCJ"
};

const baseURL = urls[process.env.REACT_APP_URL_ENV] ?? urls.GAMMA;

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const logoutURL = `${baseURL}api/auth/logout`;
const loginURL = `${baseURL}api/auth/login`;
const loginWithTokenURL = `${baseURL}api/auth/tokenLogin`;
const signupURL = `${baseURL}api/auth/user-registration`;
const phoneNumberLogin = `${baseURL}api/auth/login/number`;
const prescriptionUploadURL = `${baseURL}api/v1/order/retail/prescription-upload`;
const otpGenerate = `${baseURL}api/v1/otp/generate`;
const otpValidate = `${baseURL}api/v1/otp/validate`;
const resetPassword = `${baseURL}api/v1/utils/reset-password`;
const refreshToken = `${baseURL}api/auth/refresh`;
const switchRoleUrl = `${baseURL}api/v1/user/switch`;
const cartDetailsURL = `${baseURL}api/v1/cart-new/get-cart-details`;
const allAssociatedUsers = `${baseURL}api/v1/customers/all`;
const allAssociatedUsersList = `${baseURL}api/v1/customers/all/list`;
const createSupplierCustomerURL = `${baseURL}api/v1/customers/associate-divisions`;
const newUsersURL = `${baseURL}api/v1/user/new`;
const searchUsersURL = `${baseURL}api/v1/user/search`;
const suggestedCustomers = `${baseURL}api/v1/user/suggested-customers`;
const suggestSupplier = `${baseURL}api/v1/customers/suggest-supplier`;
const searchCustomers = `${baseURL}api/v1/user/search-customers`;
const supplierDetails = `${baseURL}api/v1/customers/approved`;
const searchSupplierProductDetails = `${baseURL}api/v1/inventory/customer/search`;
const searchSupplierProducts = `${baseURL}api/v1/inventory/supplier/search`;
const getSuggestedProducts = `${baseURL}api/v1/inventory/customer/suggested`;
const getOrderDetails = `${baseURL}api/v1/order`;
const reorder = `${baseURL}api/v1/order/reorder`;
const findOrders = `${baseURL}api/v1/order/find-orders`;
const getOrdersDateView = `${baseURL}api/v1/order/date-view`;
const orderTypes = `${baseURL}api/v1/order/allTypes`;
const orderStatuses = `${baseURL}api/v1/order/allStatuses`;
const addToCart = `${baseURL}api/v1/cart-new/create`;
const updateCart = `${baseURL}api/v1/cart-new/update`;
const updateCartHold = `${baseURL}api/v1/cart-new/hold`;
const clearCart = `${baseURL}api/v1/cart-new/clear`;
const analytics = `${baseURL}api/v1/analytics/all`;
const analyticsV2 = `${baseURL}api/v1/analytics/v2`;

const dashboardUrl = `${baseURL}api/v1/analytics/dashboard-url`;
const divisionsURL = `${baseURL}api/v1/inventory/divisions/search`;
const userGroups = `${baseURL}api/v1/userGroup/all`;
const userGroupsList = `${baseURL}api/v1/userGroup/list`;
const userGroupURL = `${baseURL}api/v1/userGroup/`;
const persistUserGroup = `${baseURL}api/v1/userGroup/persist`;
const paymentCollectionStatuses = `${baseURL}api/v1/payment/all-collection-statuses`;
const paymentRequests = `${baseURL}api/v1/payment/requests`;
const raisePaymentRequest = `${baseURL}api/v1/payment/raise-request`;
const payPayment = `${baseURL}api/v1/payment/pay`;
const userProfile = `${baseURL}api/v1/user/profile`;
const updateUserProfile = `${baseURL}api/v1/user/update-profile`;
const userNumber = `${baseURL}api/v1/user-number`;
const removeUserNumber = `${baseURL}api/v1/user-number/session`;
const removeAllUserNumbers = `${baseURL}api/v1/user-number/all-sessions`;
const invoiceDetails = `${baseURL}api/v1/invoice`;
const getAllInvoices = `${baseURL}api/v1/invoice/find-all`;
const inventoryDetails = `${baseURL}api/v1/inventory/supplier/all`;
const updateInventoryStatus = `${baseURL}api/v1/inventory/update-visibility`;
const updateProductDetails = `${baseURL}api/v1/inventory/`;
const supportCreateRequest = `${baseURL}api/v1/support/createRequest`;
const createReturn = `${baseURL}api/v1/order/return`;
const supervisorsList = `${baseURL}api/v1/representative/supervisors`;
const representatives = `${baseURL}api/v1/representative/all`;
const representativesList = `${baseURL}api/v1/representative/all-list`;
const updateRepresentative = `${baseURL}api/v1/representative/update`;
const userStatuses = `${baseURL}api/v1/user/all-user-statuses`;
const requestStatuses = `${baseURL}api/v1/user/all-request-statuses`;
const couponsSummary = `${baseURL}api/v1/coupon/summary`;
const coupons = `${baseURL}api/v1/coupon/all`;
const couponRedeem = `${baseURL}api/v1/coupon/redeem`;
const offerUpdate = `${baseURL}api/v1/offer/update`;
const offerCreate = `${baseURL}api/v1/offer/create`;
const getOffers = `${baseURL}api/v1/offer/all`;
const deleteOffer = `${baseURL}api/v1/offer/delete`;
const supplierProductsList = `${baseURL}api/v1/inventory/supplier/list`;
const supplierLedger = `${baseURL}api/v1/ledger/all`;
const supplierRepresentativeList = `${baseURL}api/v1/representative/all-list/0`;
const ledgersList = `${baseURL}api/v1/ledger/representative`;
const placeLedgerOrder = `${baseURL}api/v1/order/create-by-ledger`;
const customersUpdate = `${baseURL}api/v1/customers/update`;
const customersBlock = `${baseURL}api/v1/customers/status`;
const singleSupplierProductsList = `${baseURL}api/v1/inventory/customer/list`;
const updateOrder = `${baseURL}api/v1/order/update-order`;
const updateApprovalAction = `${baseURL}api/v1/order/update-approval-status`;
const cartPlaceOrder = `${baseURL}api/v1/order/create`;
const supplierSettings = `${baseURL}api/v1/settings/fetch`;
const receivedOrdersCustomers = `${baseURL}api/v1/order/pending/customers`;
const approverAnalytics = `${baseURL}api/v1/representative/analytics`;
const updateCustomerOrdersToInvoicing = `${baseURL}api/v1/order/manual-invoicing`;
const updateOrderByAction = `${baseURL}api/v1/order/action`;
const uploadPurchaseOrder = `${baseURL}api/v1/purchaseOrder/parseSheet`;
const createInventoryMapping = `${baseURL}api/v1/inventory-mapping`;
const updatePurchaseOrderStatus = `${baseURL}api/v1/purchaseOrder/updateUploadStatus`;
const allPurchaseOrders = `${baseURL}api/v1/purchaseOrder/all`;
const allPurchaseOrdersV2 = `${baseURL}api/v2/purchaseOrder/all`;
const newNotifications = `${baseURL}api/v1/notifications/new`;
const allNotifications = `${baseURL}api/v1/notifications/all`;
const updateNotifications = `${baseURL}api/v1/notifications/all`;
const tokenByUserId = `${baseURL}api/v1/user/token`;
const bannerImages = `${baseURL}api/v1/offer/banner-images?env=web`;
const existingDivisionMapping = `${baseURL}api/v1/division-mapping/existing-mapping`;
const deletePurchaseOrder = `${baseURL}api/v1/purchaseOrder/delete`;
const representativeCustomers = `${baseURL}api/v1/representative-user/all`;
const representativeDivisions = `${baseURL}api/v1/representative/divisions`;
const updateTargets = `${baseURL}api/v1/representative/update-targets`;
const representativeCreate = `${baseURL}api/v1/representative/create`;
const representativeUpdate = `${baseURL}api/v1/representative/update-details`;
const updatePayment = `${baseURL}api/v1/payment/update`;
const paymentTypes = `${baseURL}api/v1/payment/types`;
const paymentsDownload = `${baseURL}api/v1/payment/report`;
const getAllSubscriptions = `${baseURL}api/v1/subscriptions/all`;
const subscriptionPayment = `${baseURL}api/v1/subscriptions/pay`;
const uploadInvoices = `${baseURL}api/v1/invoice/upload`;
const invoiceAction = `${baseURL}api/v1/invoice/action`;
const invoiceDetailsWithUpdateId = `${baseURL}api/v1/invoice/details-for-update`;
const invoiceActionWithUpdateId = `${baseURL}api/v1/invoice/action-with-updateId`;
const creditNotes = `${baseURL}api/v1/credit-notes/all`;
const uploadCreditNotes = `${baseURL}api/v1/credit-notes/upload`;
const uploadInventory = `${baseURL}api/v1/inventory/inventory-ingestion`;
const uploadLedger = `${baseURL}api/v1/ledger/ledger-ingestion`;
const purchaseOrderSupplierMapping = `${baseURL}api/v2/purchaseOrder/supplier-mapping`;
const purchaseOrderInventoryMapping = `${baseURL}api/v2/purchaseOrder/inventory-mapping`;
const uploadPurchaseOrderV2 = `${baseURL}api/v2/purchaseOrder/upload`;
const suggestProductForPO = `${baseURL}api/v2/purchaseOrder/suggest-product`;
const deletePoDetails = `${baseURL}api/v2/purchaseOrder/details`;
const uploadBroadcasts = `${baseURL}api/v1/broadcast/parse-broadcasts`;
const broadcastDetails = `${baseURL}api/v1/broadcast/broadcasts`;
const broadcastTemplates = `${baseURL}api/v1/broadcast/broadcast-templates`;
const broadcastLimit = `${baseURL}api/v1/broadcast/broadcast-limit`;
const invoiceStatus = `${baseURL}api/v1/invoice/statuses`;
const outstandingInvoices = `${baseURL}api/v1/invoice/outstanding`;
const allStates = `${baseURL}api/v1/utils/states`;
const allDistricts = `${baseURL}api/v1/utils/districts/`;
const createQuotation = `${baseURL}api/v1/quotation/create`;
const quotationOrder = `${baseURL}api/v1/quotation/order`;
const deactivateQuotation = `${baseURL}api/v1/quotation/deactivate/`;
const quotationDetails = `${baseURL}api/v1/quotation/details/`;
const quotations = `${baseURL}api/v1/quotation/all`;
const updateThreshold = `${baseURL}api/v1/inventory/update/threshold`;
const userSettings = `${baseURL}api/v1/user/settings/fetch`;
const updateSettings = `${baseURL}api/v1/user/settings/save`;
const suppliersPriority = `${baseURL}api/v1/customers/priority`;
const supplierCreditProviders = `${baseURL}api/v1/credit-provider`;
const allCreditProviders = `${baseURL}api/v1/credit-provider/all`;
const creditDetails = `${baseURL}api/v1/credit-provider/details`;
const createCreditPayment = `${baseURL}api/v1/credit-provider/payment`;
const fullPageBanner = `${baseURL}api/v1/utils/full-banner`;
const saveParserConfig = `${baseURL}api/v1/parser-config/save`;
const fetchParserConfigTemplate = `${baseURL}api/v1/parser-config/fetch-template`;
const fetchFileHeader = `${baseURL}api/v1/parser-config/fetch-header`;
const updateSalesmanCodes = `${baseURL}api/v1/representative/update-salesman-codes/`;
const updateLedger = `${baseURL}api/v1/ledger/update`;
const removeLocation = `${baseURL}api/v1/ledger/remove-location`;
const searchInventoryByIds = `${baseURL}api/v1/inventory/customer/search-by-ids`;
const deleteAssociation = `${baseURL}api/v1/customers/`;
const paymentOutstanding = `${baseURL}api/v1/payment/outstanding`;
const findOrdersByProductSearch = `${baseURL}api/v1/order/find-orders/productSearch`;
const storeUserDeviceDetails = `${baseURL}api/v1/user/session-device`;
const creditFlowRedirect = `${baseURL}api/v1/credit-provider/redirect`;
const userCreditConsent = `${baseURL}api/v1/user-consent/fetch`;
const userCreditConsentSave = `${baseURL}api/v1/user-consent/save`;
const creditProviders = `${baseURL}api/v1/credit-provider/details/grid`;
const getPreCarts = `${baseURL}api/v1/preCart/all`;
const addToCartFromPreCarts = `${baseURL}api/v1/preCart/add-to-cart`;
const deletePreCartItem = `${baseURL}api/v1/preCart/delete`;
const addToPreCart = `${baseURL}api/v1/preCart/create`;
const updatePreCartItem = `${baseURL}api/v1/preCart/update`;
const updateLocation = `${baseURL}api/v1/location/update`;
const getRepresentativeLocation = `${baseURL}api/v1/location/get`;
const updatePurchaseOrderDetail = `${baseURL}api/v2/purchaseOrder/update`;
const getRepresentativeAttendance = `${baseURL}api/v1/representative/attendance`;
const getRepresentativeLedgers = `${baseURL}api/v1/ledger/representative`;
const logisticOrders = `${baseURL}api/v1/logistics/grid`;
const logisticsDropdown = `${baseURL}api/v1/logistics/destinations`;
const logisticsQuotes = `${baseURL}api/v1/logistics/quotes`;
// const createLogisticsOrder = `${baseURL}api/v1/logistics/order`;
const createLogisticsOrder = `${baseURL}api/v1/logistics/order-pay`;
const cancelLogisticsOrder = `${baseURL}api/v1/logistics/cancel`;
const createLedgerOrder = `${baseURL}api/v1/order/create-ledger-order`;
const flipPaymentNotificationSetting = `${baseURL}api/v1/settings/update/paymentNotification`;
const globalSupplierIds = `${baseURL}api/v1/inventory/global-suppliers`;
const getAllDrugInfo = `${baseURL}api/v1/product/all`;
const getDrugSubs = `${baseURL}api/v1/product/find`;
const suggestProduct = `${baseURL}api/v1/product/send-product`;

const APIConstants = {
  createLedgerOrder,
  getRepresentativeLedgers,
  getRepresentativeAttendance,
  updateCartHold,
  updatePurchaseOrderDetail,
  getRepresentativeLocation,
  defaultHeaders,
  baseURL,
  loginURL,
  signupURL,
  phoneNumberLogin,
  otpGenerate,
  otpValidate,
  resetPassword,
  prescriptionUploadURL,
  cartDetailsURL,
  allAssociatedUsers,
  allAssociatedUsersList,
  newUsersURL,
  searchUsersURL,
  createSupplierCustomerURL,
  suggestedCustomers,
  suggestSupplier,
  searchCustomers,
  supplierDetails,
  searchSupplierProductDetails,
  getSuggestedProducts,
  getOrderDetails,
  reorder,
  orderTypes,
  findOrders,
  getOrdersDateView,
  orderStatuses,
  switchRoleUrl,
  addToCart,
  clearCart,
  analytics,
  analyticsV2,
  divisionsURL,
  userGroups,
  userGroupsList,
  userGroupURL,
  persistUserGroup,
  paymentCollectionStatuses,
  paymentRequests,
  raisePaymentRequest,
  payPayment,
  userProfile,
  userNumber,
  removeUserNumber,
  removeAllUserNumbers,
  updateUserProfile,
  invoiceDetails,
  getAllInvoices,
  inventoryDetails,
  updateInventoryStatus,
  updateProductDetails,
  supportCreateRequest,
  createReturn,
  supervisorsList,
  representatives,
  representativesList,
  updateRepresentative,
  userStatuses,
  requestStatuses,
  couponRedeem,
  coupons,
  couponsSummary,
  offerCreate,
  offerUpdate,
  getOffers,
  deleteOffer,
  supplierProductsList,
  supplierLedger,
  supplierRepresentativeList,
  ledgersList,
  placeLedgerOrder,
  customersUpdate,
  singleSupplierProductsList,
  updateOrder,
  updateApprovalAction,
  updateCart,
  cartPlaceOrder,
  supplierSettings,
  refreshToken,
  receivedOrdersCustomers,
  updateCustomerOrdersToInvoicing,
  updateOrderByAction,
  uploadPurchaseOrder,
  searchSupplierProducts,
  createInventoryMapping,
  updatePurchaseOrderStatus,
  allPurchaseOrders,
  newNotifications,
  allNotifications,
  updateNotifications,
  logoutURL,
  loginWithTokenURL,
  tokenByUserId,
  bannerImages,
  existingDivisionMapping,
  deletePurchaseOrder,
  representativeCustomers,
  representativeDivisions,
  approverAnalytics,
  updateTargets,
  representativeCreate,
  representativeUpdate,
  updatePayment,
  paymentTypes,
  paymentsDownload,
  getAllSubscriptions,
  subscriptionPayment,
  uploadInvoices,
  invoiceAction,
  invoiceActionWithUpdateId,
  invoiceDetailsWithUpdateId,
  creditNotes,
  uploadCreditNotes,
  uploadInventory,
  uploadLedger,
  purchaseOrderSupplierMapping,
  uploadPurchaseOrderV2,
  suggestProductForPO,
  purchaseOrderInventoryMapping,
  deletePoDetails,
  allPurchaseOrdersV2,
  dashboardUrl,
  customersBlock,
  uploadBroadcasts,
  broadcastDetails,
  broadcastTemplates,
  broadcastLimit,
  invoiceStatus,
  allStates,
  allDistricts,
  quotationDetails,
  quotations,
  deactivateQuotation,
  quotationOrder,
  createQuotation,
  updateThreshold,
  userSettings,
  updateSettings,
  suppliersPriority,
  outstandingInvoices,
  supplierCreditProviders,
  allCreditProviders,
  creditDetails,
  createCreditPayment,
  fullPageBanner,
  deleteAssociation,
  saveParserConfig,
  fetchParserConfigTemplate,
  fetchFileHeader,
  updateSalesmanCodes,
  updateLedger,
  removeLocation,
  paymentOutstanding,
  searchInventoryByIds,
  findOrdersByProductSearch,
  storeUserDeviceDetails,
  creditFlowRedirect,
  userCreditConsent,
  userCreditConsentSave,
  creditProviders,
  getPreCarts,
  addToCartFromPreCarts,
  deletePreCartItem,
  addToPreCart,
  updatePreCartItem,
  updateLocation,
  logisticOrders,
  logisticsDropdown,
  logisticsQuotes,
  createLogisticsOrder,
  cancelLogisticsOrder,
  flipPaymentNotificationSetting,
  globalSupplierIds,
  getAllDrugInfo,
  getDrugSubs,
  suggestProduct
};

export default APIConstants;
